export const settings: google.maps.MapOptions = {
  center: { lat: 42.877742, lng: -97.380979 },
  draggable: true,
  mapTypeControl: false,
  maxZoom: 19,
  minZoom: 4,
  rotateControl: false,
  scrollwheel: true,
  streetViewControl: false,
  zoom: 4,
  zoomControl: true,
};

export const styles: google.maps.MapTypeStyle[] = [
  { featureType: 'all', stylers: [{ saturation: -40 }] },
  {
    elementType: 'geometry.fill',
    featureType: 'water',
    stylers: [{ color: '#bfdcfd' }],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape',
    stylers: [{ color: '#f6f6f6' }],
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [{ color: '#f6bb7b' }, { saturation: 0 }],
  },
  {
    elementType: 'labels',
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
];
