import { type Location, type Strings, type WebConfig } from '@koala/sdk';
import Link from 'next/link';
import { CTA } from '@/constants/cta';
import { LOCATION_ATTRIBUTE_POSITIONS } from '@/constants/locations';
import { type IBasketFulfillment } from '@/types/fulfillment';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { googleMapsUrl } from '@/utils/locations';
import {
  interpolateLocationCtaValue,
  reconcileCustomAttributeWithGlobalValue,
  safelyGetString,
} from '@/utils/stringHelpers';

/** @TODO this entire component needs to be refactored. */
const deriveCTAValues = (
  strings: Strings,
  location: Location,
  position: string,
  positionId: number,
  basketFulfillment: IBasketFulfillment,
  config: WebConfig,
) => {
  const globalValue = safelyGetString(strings, `location_ctas.${position}_value`, location);
  const globalLabel = safelyGetString(strings, `location_ctas.${position}_label`, location);
  const { label, value } = reconcileCustomAttributeWithGlobalValue(
    // @ts-expect-error @TODO improve typing of custom attributes
    location.attributes,
    positionId,
    globalValue,
    globalLabel,
  );

  const ctaObject: CTAObject = {
    value,
    label,
    interpolatedValue: interpolateLocationCtaValue(value, location, config).finalValue,
  };

  // If we have an existing delivery address AND the CTA is a delivery trigger, treat the CTA
  // like the pickup button and just navigate to store menu page - the reconciliation modal will handle the rest
  if (basketFulfillment?.address && value === CTA.DELIVERY_URL) {
    ctaObject.interpolatedValue = interpolateLocationCtaValue(
      CTA.PICKUP_URL,
      location,
      config,
    ).finalValue;
  }

  return ctaObject;
};

interface CTAObject {
  label: string;
  value: string;
  interpolatedValue: string;
}

const derivePrimaryCtas = (
  ctaObject: CTAObject,
  location: Location,
  gaAction: string,
  classes: string,
  hideDeliveryTrigger: boolean,
  basketFulfillment: IBasketFulfillment,
  toggleFulfillmentModal: () => void,
) => {
  const escapedLocationLabel = location.label?.replace(/'/g, '');

  if (ctaObject.value === CTA.DELIVERY_URL && !basketFulfillment?.address) {
    return (
      <button
        className={classes}
        data-css-override="MapTooltipPrimaryButton"
        aria-label={`Order delivery from {ctaObject.label}`}
        onClick={() => {
          toggleFulfillmentModal();
          fireGaEvent(gaCats.order, gaAction, {
            label: escapedLocationLabel,
          });
        }}
        style={{ display: hideDeliveryTrigger ? 'none' : 'block' }}
      >
        {ctaObject.label}
      </button>
    );
  }

  if (ctaObject.label && ctaObject.value && location.provider_enabled) {
    return (
      <Link
        href={ctaObject.interpolatedValue}
        className={classes}
        onClick={() =>
          fireGaEvent(gaCats.order, gaAction, {
            label: escapedLocationLabel,
          })
        }
        data-css-override="MapTooltipPrimaryButton"
      >
        {ctaObject.label}
      </Link>
    );
  }

  return null;
};

export const mapTooltip = (
  location: Location,
  toggleFulfillmentModal: () => void,
  theme: WebConfig,
  strings: Strings,
  basketFulfillment: IBasketFulfillment,
  config: WebConfig,
) => {
  // We should only render the Koala-specific delivery trigger if the location supports delivery. Otherwise, we should render nothing
  const hideDeliveryTrigger = !location.supports_delivery;

  // Location inactive
  const disabled = !location.provider_enabled;
  const locationInactive = safelyGetString(strings, 'locations.location_inactive');

  // Derive primary CTA values
  // @ts-expect-error
  const primaryCtaObject: CTAObject =
    !disabled &&
    deriveCTAValues(
      strings,
      location,
      'primary',
      LOCATION_ATTRIBUTE_POSITIONS.PRIMARY_ORDER_CTA,
      basketFulfillment,
      config,
    );
  // @ts-expect-error
  const secondaryCtaObject: CTAObject =
    !disabled &&
    deriveCTAValues(
      strings,
      location,
      'secondary',
      LOCATION_ATTRIBUTE_POSITIONS.SECONDARY_ORDER_CTA,
      basketFulfillment,
      config,
    );

  // Location cross street
  const getCrossStreetDisplayString = () => {
    if (!theme.locations?.cross_street || !location.cross_street) {
      return '';
    }
    const crossStreetLabel: string | null = safelyGetString(
      strings,
      'locations.cross_street_label',
    );
    return crossStreetLabel
      ? `${crossStreetLabel + ` `}${location.cross_street}<br />`
      : `${location.cross_street}<br />`;
  };

  const primaryCtaTheme: string = theme.location_card?.primary_button_theme;
  const secondaryCtaTheme: string = theme.location_card?.secondary_button_theme;

  return (
    <div className="tooltip">
      <div className="tooltip-info">
        <h3>{location.cached_data?.label}</h3>
        <p>
          <a href={googleMapsUrl(location.cached_data)} target="_blank" rel="noreferrer">
            {location.street_address}
            <br />
            {getCrossStreetDisplayString()}
            {location.city},{location.cached_data?.state ?? ''} {location.zip_code ?? ''}
          </a>
          <br />
          <span>{location.cached_data?.phone_number ?? ''}</span>
        </p>
        <p className="tooltip-distance">
          {location.distance_miles
            ? `${Math.round(location.distance_miles * 100) / 100} miles away`
            : ''}
        </p>
        {disabled ? (
          <p style={{ marginTop: '10px' }}>
            <i>{locationInactive}</i>
          </p>
        ) : null}
      </div>
      <div>
        {/* Convert to a normal jsx component */}
        {derivePrimaryCtas(
          primaryCtaObject,
          location,
          gaActions.mapPrimaryCta,
          `tooltip-cta tooltip-cta-${primaryCtaTheme}`,
          hideDeliveryTrigger,
          basketFulfillment,
          toggleFulfillmentModal,
        )}

        {/* Convert to a normal jsx component */}
        {derivePrimaryCtas(
          secondaryCtaObject,
          location,
          gaActions.mapSecondaryCta,
          `tooltip-cta tooltip-cta-${secondaryCtaTheme}`,
          hideDeliveryTrigger,
          basketFulfillment,
          toggleFulfillmentModal,
        )}
      </div>
    </div>
  );
};
