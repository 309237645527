import { darken, getContrast, readableColor } from 'polished';
import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { COLORS, LAYOUT } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

export const StyledMapOuter = styled.div`
  bottom: 0;
  overflow: hidden;
  position: sticky;
  right: 0;
  top: 0;
`;

export const StyledMap = styled.div(({ theme }) => {
  return {
    background: COLORS.BACKGROUNDGRAY,
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',

    '.tooltip': {
      display: 'flex',
      lineHeight: LAYOUT.LINE_HEIGHT,
      padding: '5px 0px 5px 5px',
      maxWidth: '350px',
      minWidth: '300px',

      '> div': {
        '&:first-child': {
          flex: 2,
        },

        '&:last-child': {
          display: 'flex',
          flexDirection: 'column',

          p: {
            margin: `4px 0 0 ${LAYOUT.GUTTER}px`,
            textAlign: 'right',
            a: {
              fontFamily: theme.primary_font.medium.font_family,
              textDecoration: 'underline',
            },
          },
        },
      },

      h3: {
        display: 'inline-block',
        margin: '0 0px 8px',
        paddingRight: '24px',
        position: 'relative',
        ...getFontStyles(
          theme.location_card.secondary_font,
          ['font_family', 'font_size', 'font_style', 'color', 'line_height'],
          {
            font_size: (value) => (typeof value === 'number' ? value : 16),
            line_height: (value) => (typeof value === 'number' ? value : 20),
            color: (value) => {
              if (value && getContrast(COLORS.WHITE, String(value)) > 4.5) {
                return value;
              }
              return COLORS.TEXT;
            },
          },
        ),
      },

      p: {
        margin: 0,
        ...getFontStyles(
          theme.location_card.font,
          ['font_family', 'font_weight', 'font_style', 'color'],
          {
            color: (value) => {
              // @TODO: `getFontStyles` type seems mangled
              // @ts-expect-error: value type is wrong
              if (value && getContrast(COLORS.WHITE, value) > 4.5) {
                return value;
              }
              return COLORS.TEXT;
            },
          },
        ),

        a: {
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
          },
        },

        '&.tooltip-distance': {
          fontStyle: 'italic',
          marginTop: '5px',
        },
      },

      '.tooltip-cta': {
        transition: 'all 150ms linear',
        borderRadius: `${theme.buttons.primary_button_border_radius}px`,
        cursor: 'pointer',
        display: 'inline-block',
        fontFamily: theme.primary_font.medium.font_family,
        fontSize: '12px',
        marginBottom: '10px',
        padding: '10px 20px 8px',
        textAlign: 'center',

        '&.tooltip-cta-primary': {
          background: theme.buttons.primary_button_background_color,
          border: `1px solid ${theme.buttons.primary_button_border_color}`,
          ...getFontStyles(
            theme.buttons.primary_font,
            ['color', 'font_family', 'font_style', 'font_weight', 'line_height'],
            {
              color: (value) => {
                if (value === 'revert' || value === null) {
                  return safePolished(readableColor)(theme.buttons.primary_button_background_color);
                }

                return value;
              },
            },
          ),

          '&:hover': {
            background: `${safePolished(darken)(
              0.025,
              theme.buttons.primary_button_background_color,
            )}`,
          },
        },

        '&.tooltip-cta-secondary': {
          background: theme.buttons.secondary_button_background_color,
          border: `1px solid ${theme.buttons.secondary_button_border_color}`,
          ...getFontStyles(
            theme.buttons.secondary_font,
            ['color', 'font_family', 'font_style', 'font_weight', 'line_height'],
            {
              color: (value) => {
                if (value === 'revert' || value === null) {
                  return safePolished(readableColor)(
                    theme.buttons.secondary_button_background_color,
                  );
                }

                return value;
              },
            },
          ),

          '&:hover': {
            background: `${safePolished(darken)(
              0.025,
              theme.buttons.secondary_button_background_color,
            )}`,
          },
        },

        '&.tooltip-cta-knockout': {
          background: theme.buttons.knockout_button_background_color,
          border: `1px solid ${theme.buttons.knockout_button_border_color}`,
          ...getFontStyles(
            theme.buttons.knockout_font,
            ['color', 'font_family', 'font_style', 'font_weight', 'line_height'],
            {
              color: (value) => {
                if (value === 'revert' || value === null) {
                  return theme.buttons.knockout_button_border_color;
                }

                return value;
              },
            },
          ),

          '&:hover': {
            background: `${safePolished(darken)(
              0.025,
              theme.buttons.knockout_button_background_color,
            )}`,
            ...getFontStyles(theme.buttons.knockout_font, ['color'], {
              /** @FIXME font refactor. color isn't actually null, it defaults to revert in the config screen. Should we treat `revert` like `null` */
              color: (value) => (value ? safePolished(darken)(0.025, value) : 'inherit'),
            }),
          },
        },
      },
    },

    '.tooltip-info': {
      paddingRight: '10px',
    },

    '.mapboxgl-popup-content': {
      padding: '15px 20px 15px 15px',
      '.mapboxgl-popup-close-button': {
        top: '5px',
      },
    },

    '@media (max-width: 767px)': {
      height: `calc(
        100vh - ${LAYOUT.MOBILE_HEADERHEIGHT}px -
          ${LAYOUT.MOBILE_MAP_LOCATION_CARD_HEIGHT}px -
          ${LAYOUT.MOBILE_LIST_MAP_FOOTER_HEIGHT}px
      )`,
    },
  };
});

export const StyledMapSearchButton = styled(StyledPrimaryButton)`
  animation: fadein 0.5s;
  background: ${({ theme }) =>
    theme.buttons.secondary_button_background_color || 'rgba(255, 255, 255, 0.8)'};
  left: 10px;
  max-width: 300px;
  position: absolute;
  top: 10px;
  z-index: 2;

  // Account for brands with a null secondary CTA bg
  ${({ theme }) =>
    !theme.buttons.secondary_button_background_color &&
    `
    &:hover {
      background: ${safePolished(darken)(
        0.025,
        theme.buttons.secondary_button_background_color || 'rgba(255, 255, 255, 0.7)',
      )};
    }
  `}

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
