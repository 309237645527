import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { CONVEYANCE_TYPES, type Location, type Strings } from '@koala/sdk';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { useTheme } from 'styled-components';
import { type IBasketFulfillment } from '@/types/fulfillment';
import { settings, styles } from '@/components/locations/map/google/map-config';
import {
  StyledMap,
  StyledMapOuter,
  StyledMapSearchButton,
} from '@/components/locations/map/styles';
import { mapTooltip } from '@/components/locations/map/tooltip';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';

interface IMap {
  locations: Location[];
  activeLocationId: number | null;
  setActiveLocation: (id: number) => void;
  toggleFulfillmentModal: (show: boolean, location: Location, conveyanceType: string) => void;
  fetchCenterCoords: (center: number[], radius: number) => void;
  strings: Strings;
  basketFulfillment: IBasketFulfillment;
}

const LocationsMap = ({
  locations,
  activeLocationId,
  setActiveLocation,
  toggleFulfillmentModal,
  fetchCenterCoords,
  strings,
  basketFulfillment,
}: IMap) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [showSearchAreaButton, setShowSearchAreaButton] = useState(false);
  // Used to ensure a marker is present before showing the info window
  const markerRef = useRef<google.maps.Marker | undefined>(undefined);
  const theme = useTheme();
  const markerIcon = {
    path: 'M0.399902 21.0595C0.399902 9.64069 9.57363 0.399994 20.7643 0.399994C31.9549 0.399994 41.1287 9.64069 40.9985 21.0595C40.9985 31.8737 23.0819 51.3109 21.2763 53.2697C21.2194 53.3314 21.1785 53.3758 21.1547 53.402C21.0245 53.534 20.8944 53.6 20.6992 53.6C20.504 53.6 20.3739 53.534 20.2438 53.402C20.2199 53.3758 20.1791 53.3314 20.1222 53.2697C18.3165 51.3109 0.399902 31.8737 0.399902 21.0595ZM21 29C25.9706 29 30 24.9706 30 20C30 15.0294 25.9706 11 21 11C16.0294 11 12 15.0294 12 20C12 24.9706 16.0294 29 21 29Z',
    fillColor: theme.global.primary_active_color,
    strokeWeight: 0,
    rotation: 0,
    scale: 0.7,
  };

  const mapOptions = {
    ...settings,
    disableDefaultUI: true,
    styles,
  };
  delete mapOptions.center;
  delete mapOptions.zoom;

  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
  const onMount = useCallback(() => setMap(null), []);

  const activeLocation = locations?.find((location) => location.id === activeLocationId);

  const locationMarkers = useMemo(
    () =>
      locations?.length
        ? locations.map((location) => {
            return {
              id: location.id,
              label: location.label,
              position: {
                lat: location.latitude,
                lng: location.longitude,
              },
            };
          })
        : [],
    [locations],
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY ?? '',
  });

  const handleBoundsChanged = () => {
    if (map) {
      setBounds(map.getBounds() ?? null);
    }
  };

  const handleSearchArea = () => {
    if (!bounds) {
      return;
    }

    const sw = bounds.getSouthWest(); // Bottom-left corner
    const mapCenter = map?.getCenter(); // Center

    if (!mapCenter?.lat() || !mapCenter?.lng() || !sw.lat() || !sw.lng()) {
      return;
    }

    // Approximate degrees to miles
    const horizontalDistance = (mapCenter?.lng() - sw.lng()) * 69.2;
    const verticalDistance = (mapCenter?.lat() - sw.lat()) * 69.2;
    const largestRadialDistance: number = Math.max(horizontalDistance, verticalDistance);

    fetchCenterCoords([mapCenter?.lat(), mapCenter?.lng()], largestRadialDistance);

    setShowSearchAreaButton(false);
    fireGaEvent(gaCats.findShop, gaActions.mapSearchArea);
  };

  useEffect(() => {
    if (map && locationMarkers.length) {
      const bounds = new google.maps.LatLngBounds();
      locationMarkers.forEach((marker) => {
        bounds.extend(marker.position);
      });
      map.fitBounds(bounds);
    }
  }, [map, locationMarkers]);

  return (
    <>
      {isLoaded && !window.matchMedia(`(max-width: 767px)`).matches ? (
        <StyledMapOuter>
          {showSearchAreaButton && (
            <StyledMapSearchButton onClick={handleSearchArea} $buttonTheme="secondary">
              Search This Area
            </StyledMapSearchButton>
          )}
          <StyledMap>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
              }}
              center={settings.center as google.maps.LatLngLiteral}
              zoom={settings.zoom ?? 10}
              options={{
                ...mapOptions,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
              }}
              onLoad={onLoad}
              onUnmount={onMount}
              onBoundsChanged={() => handleBoundsChanged()}
              onDragEnd={() => {
                handleBoundsChanged();
                setShowSearchAreaButton(true);
              }}
            >
              {locationMarkers.map((location) => (
                <Marker
                  key={location.id}
                  title={`Click to expand details ${location.label}`}
                  position={{ lat: location.position.lat, lng: location.position.lng }}
                  icon={{
                    ...markerIcon,
                    fillOpacity: activeLocationId === location.id ? 1 : 0.5,
                    anchor: new google.maps.Point(20, 53),
                  }}
                  onLoad={(marker) => (markerRef.current = marker)}
                  onClick={() => setActiveLocation(location.id)}
                />
              ))}
              {markerRef?.current && activeLocation && (
                <InfoWindow
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -40),
                  }}
                  position={{
                    lat: activeLocation?.latitude ?? 0,
                    lng: activeLocation?.longitude ?? 0,
                  }}
                  onCloseClick={() => setActiveLocation(0)}
                >
                  <div>
                    {/* @TODO, make this a component and remove the function */}
                    {mapTooltip(
                      activeLocation,
                      () => toggleFulfillmentModal(true, activeLocation, CONVEYANCE_TYPES.DELIVERY),
                      theme,
                      strings,
                      basketFulfillment,
                      theme,
                    )}
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </StyledMap>
        </StyledMapOuter>
      ) : null}
    </>
  );
};

export default LocationsMap;
